import React from 'react';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

const GA_MEASUREMENT_ID: string | undefined =
  process.env.REACT_APP_GA_MEASUREMENT_ID;

const GoogleAndMetaAnalytics: FC = () => {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
          <!-- Global site tag (gtag.js) - Google Ads: 724926594 -->
          gtag('config', 'AW-724926594');
          <!-- Global site tag (gtag.js) - Google Ads: 10802180053 -->
          gtag('config', 'AW-10802180053');

        `}
      </script>

      <script>
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1298178180352856');
        fbq('track', 'PageView');
        `}
      </script>

      <noscript>
        {`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=1298178180352856&ev=PageView&noscript=1"
        />
        `}
      </noscript>
    </Helmet>
  );
};

export default GoogleAndMetaAnalytics;
